import { IconModel } from '@/landing/models/IconModel';

export const TestimonialsQuotesIcon = ({
  width,
  height,
  fill,
}: IconModel) => {
  const iconWidth = width ? width : 42;
  const iconHeight = height ? height : 34;
  const iconFill = fill ? fill : '#F97316';

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 42 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        Authentic Testimonials: Hear What Nerdbord Students Have to
        Say
      </title>
      <desc>
        Explore genuine testimonials from satisfied Nerdbord students.
        Discover how our AI-powered learning platform has helped them
        succeed in their tech journey. Get inspired by their stories
        and learn how Nerdbord can empower you to achieve your goals
        in the world of technology.
      </desc>
      <path
        d="M41.2008 0V7.79999H38.8008C36.8675 7.79999 35.3341 8.1 34.2008 8.7C33.0675 9.3 32.2341 10.3333 31.7008 11.8C31.1674 13.2 30.9008 15.1 30.9008 17.5V24.9L28.0008 19.3C28.4675 18.9667 29.1341 18.7 30.0008 18.5C30.8674 18.2333 31.7674 18.1 32.7008 18.1C34.9008 18.1 36.7341 18.8 38.2008 20.2C39.6675 21.5333 40.4008 23.4 40.4008 25.8C40.4008 28.0667 39.7008 30 38.3008 31.6C36.9008 33.1333 34.9008 33.9 32.3008 33.9C30.6341 33.9 29.0674 33.5333 27.6008 32.8C26.2008 32 25.0674 30.6667 24.2008 28.8C23.3341 26.8667 22.9008 24.2333 22.9008 20.9V19.2C22.9008 14.4667 23.5341 10.7 24.8008 7.9C26.1341 5.1 28.0341 3.1 30.5008 1.9C32.9674 0.633333 35.9674 0 39.5008 0H41.2008ZM19.1008 0V7.79999H16.7008C14.7674 7.79999 13.2341 8.1 12.1008 8.7C10.9674 9.3 10.1341 10.3333 9.60078 11.8C9.06745 13.2 8.80078 15.1 8.80078 17.5V24.9L5.90078 19.3C6.36745 18.9667 7.03411 18.7 7.90078 18.5C8.76745 18.2333 9.66745 18.1 10.6008 18.1C12.8008 18.1 14.6341 18.8 16.1008 20.2C17.5674 21.5333 18.3008 23.4 18.3008 25.8C18.3008 28.0667 17.6008 30 16.2008 31.6C14.8008 33.1333 12.8008 33.9 10.2008 33.9C8.53411 33.9 6.96745 33.5333 5.50078 32.8C4.10078 32 2.96745 30.6667 2.10078 28.8C1.23411 26.8667 0.800781 24.2333 0.800781 20.9V19.2C0.800781 14.4667 1.43411 10.7 2.70078 7.9C4.03411 5.1 5.93411 3.1 8.40078 1.9C10.8674 0.633333 13.8674 0 17.4008 0H19.1008Z"
        fill={iconFill}
      />
    </svg>
  );
};
