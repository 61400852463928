import { TypographyClasses } from '@/landing/styles/tokens';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import styles from './Industry.module.scss';
import IndustryBox from './IndustryBox';

const cx = classNames.bind(styles);

const Industry = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const boxes = useMemo(
    () => [
      {
        emoji: '🌍',
        title: t('industry.tired-of-tutorials'),
        content: t('industry.independent-professional'),
      },
      {
        emoji: '💰',
        title: t('industry.test-yourself'),
        content: t('industry.projects-like-workplace'),
      },
      {
        emoji: '🕒',
        title: t('industry.lost-in-learning'),
        content: t('industry.personalized-guidance'),
      },
    ],
    [t]
  );

  return (
    <section id="benefits" className={styles.industry}>
      <div className={cx(TypographyClasses.wrapper)}>
        <p className={cx(TypographyClasses.subtitle)}>
          {t('industry.coding-in-js')}
        </p>
        <h2 className={cx(TypographyClasses.title)}>
          {t('industry.why-learning-with-nerdbord')}
        </h2>
        <p className={cx(TypographyClasses.subheader)}>
          {t('industry.complex-software-dev')}
        </p>
      </div>
      <div className={cx('grid')}>
        {boxes.map((box) => (
          <IndustryBox
            key={box.emoji}
            emoji={box.emoji}
            title={box.title}
            content={box.content}
          />
        ))}
      </div>
    </section>
  );
};

export default Industry;
