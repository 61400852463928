import { TypographyClasses } from '@/landing/styles/tokens';
import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { FaqIconArrowDown } from '../../Icons/FaqIcon/FaqIconArrowDown';
import { FaqIconArrowUp } from '../../Icons/FaqIcon/FaqIconArrowUp';
import styles from './FaqItem.module.scss';

const cx = classNames.bind(styles);

interface FaqItemProps {
  header: string;
  text: string;
}

const FaqItem = ({ header, text }: FaqItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={cx(TypographyClasses.wrapper)}>
      <div className={cx(TypographyClasses.container)}>
        <div
          className={cx('toggle')}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-label={isExpanded ? 'Collapse FAQ' : 'Expand FAQ'}
        >
          <div className={cx('question')}>
            <h3 className={cx(TypographyClasses.title)}>{header}</h3>
            <div className={cx('icon')}>
              {isExpanded ? (
                <FaqIconArrowUp width={12} height={8} />
              ) : (
                <FaqIconArrowDown width={12} height={8} />
              )}
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            className={cx('collapse')}
          >
            <div className={cx(TypographyClasses.content)}>
              <p className={cx(TypographyClasses.subtitle)}>{text}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FaqItem;
