import { TypographyClasses } from '@/landing/styles/tokens';
import AppreciatedImg1 from '@/public/images/landing/appreciated1.webp';
import AppreciatedImg2 from '@/public/images/landing/appreciated2.webp';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import styles from './Appreciated.module.scss';

const cx = classNames.bind(styles);

const Appreciated = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  return (
    <section className={cx('appreciated')}>
      <div className={cx(TypographyClasses.wrapper)}>
        <div className={cx(TypographyClasses.container)}>
          <p className={cx(TypographyClasses.subheader)}>
            {t('appreciated.small-victories')}
          </p>
          <h2 className={cx(TypographyClasses.title)}>
            {t('appreciated.in-competitions')}
          </h2>
          <p className={cx(TypographyClasses.content)}>
            {t('appreciated.recognition')}
          </p>
        </div>
        <div className={cx(`icons-${TypographyClasses.container}`)}>
          <Image
            src={AppreciatedImg1}
            alt={t('appreciated.awards-alt')}
            className={cx('img')}
            loading="lazy"
          />
          <Image
            src={AppreciatedImg2}
            alt={t('appreciated.startup-alt')}
            className={cx('img')}
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
};

export default Appreciated;
