import { TypographyClasses } from '@/landing/styles/tokens';
import classNames from 'classnames/bind';
import styles from './ReinventedFeatures.module.scss';

type ReinventedFeaturesProps = {
  side: 'right' | 'left';
  title: string;
  content: string;
  icon?: React.ReactElement;
  image?: React.ReactElement;
};

const cx = classNames.bind(styles);

const ReinventedFeatures = ({
  side,
  title,
  content,
  icon,
  image,
}: ReinventedFeaturesProps) => {
  return (
    <div className={cx(TypographyClasses.container)}>
      <div className={cx(`${TypographyClasses.content}-${side}`)}>
        <div className={cx(`${TypographyClasses.title}-wrapper`)}>
          <div className={cx(TypographyClasses.title)}>{title}</div>
        </div>
        <p className={cx(TypographyClasses.subtitle)}>{content}</p>
      </div>
      <div className={cx(`${TypographyClasses.wrapper}-${side}`)}>
        {image}
      </div>
    </div>
  );
};

export default ReinventedFeatures;
