import { TypographyClasses } from '@/landing/styles/tokens';
import classNames from 'classnames/bind';
import React, {
  ButtonHTMLAttributes,
  PropsWithChildren,
} from 'react';
import styles from './Button.module.scss';

interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?:
    | 'primary'
    | 'primary-round'
    | 'secondary'
    | 'secondary-round';
}

const cx = classNames.bind(styles);

export const Button = ({
  children,
  variant = 'primary',
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const buttonClass = cx({
    [`${TypographyClasses.button}-primary`]: variant === 'primary',

    [`${TypographyClasses.button}-secondary`]:
      variant === 'secondary',
    [`${TypographyClasses.button}-primary-round`]:
      variant === 'primary-round',
    [`${TypographyClasses.button}-secondary-round`]:
      variant === 'secondary-round',
    disabled: props.disabled,
  });

  return (
    <button className={buttonClass} {...props}>
      {children}
    </button>
  );
};
