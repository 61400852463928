import { TypographyClasses } from '@/landing/styles/tokens';
import DiscordImg from '@/public/images/landing/discord.webp';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Button } from '../../UI/Button';
import styles from './InTouch.module.scss';

const cx = classNames.bind(styles);

const InTouch = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  return (
    <section className={cx('intouch')}>
      <div className={cx(TypographyClasses.wrapper)}>
        <div className={cx(TypographyClasses.content)}>
          <p className={cx(TypographyClasses.subheader)}>
            {t('component.intouch.subheader')}
          </p>
          <h2 className={cx(TypographyClasses.title)}>
            {t('component.intouch.title')}
          </h2>
        </div>
        <div className={cx(`discord-${TypographyClasses.wrapper}`)}>
          <div
            className={cx(`discord-${TypographyClasses.container}`)}
          >
            <p className={cx(`discord-${TypographyClasses.header}`)}>
              {t('component.intouch.discord-wrapper.header')}
            </p>
            <p
              className={cx(`discord-${TypographyClasses.subheader}`)}
            >
              {t('component.intouch.discord-wrapper.subheader')}
            </p>
            <Button
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open(
                    'https://discord.com/invite/W7UJVJGD7Y',
                    '_blank'
                  );
                }
              }}
              variant="secondary"
              aria-label="Join our Discord"
            >
              <Image
                src={DiscordImg}
                alt="Discord logo with Nerdbord chat about  software development and design"
                width={20}
                loading="lazy"
              />
              {t('component.intouch.discord-wrapper.button')}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InTouch;
