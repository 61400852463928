import { Container } from '@/components/Container/Container';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import styles from './Hero.module.scss';

const Hero = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  return (
    <section className={styles.hero}>
      <Container>
        <div className={styles.headerLayout}>
          <div className={styles.heroHeaderWrapper}>
            <h1 className={styles.heroHeader}>
              {t('here.title')}{' '}
              <span className={styles.highlight}>
                {t('here.title-2')}.
              </span>
            </h1>
          </div>
          <p>{t('here.description')}</p>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
