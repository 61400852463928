import { TypographyClasses } from '@/landing/styles/tokens';
import contract1 from '@/public/images/contract-1.jpg';
import contract2 from '@/public/images/contract-2.jpg';
import contract3 from '@/public/images/contract-3.jpg';
import contract4 from '@/public/images/contract-4.jpg';
import contract5 from '@/public/images/contract-5.jpg';

import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Button } from '../../UI/Button';
import styles from './Portfolio.module.scss';

const cx = classNames.bind(styles);

const imageSources = [
  { src: contract1, alt: 'Contract 1' },
  { src: contract2, alt: 'Contract 2' },
  { src: contract3, alt: 'Contract 3' },
  { src: contract4, alt: 'Contract 4' },
  { src: contract5, alt: 'Contract 5' },
  { src: contract2, alt: 'Contract 6' },
];

const images = imageSources.map(({ src, alt }, index) => (
  <Image
    width={341}
    height={345}
    key={`image-${index}`}
    src={src}
    alt={alt}
    loading="lazy"
  />
));

const Portfolio = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const handleJoinClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const programsSection = document.querySelector('#our-programs');
    if (programsSection) {
      programsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="portfolio" className={cx('portfolio')}>
      <div className={cx(TypographyClasses.wrapper)}>
        <div className={cx(TypographyClasses.container)}>
          <h2
            className={cx(TypographyClasses.title)}
            style={{ fontSize: '36px' }}
          >
            {t('portfolio.title')}
          </h2>
          <p className={cx(TypographyClasses.subtitle)}>
            {t('portfolio.subheader')}
          </p>
          <div>
            <Button onClick={handleJoinClick} variant="primary-round">
              {t('join.join-for-free')}
            </Button>
          </div>
        </div>

        <div
          className={cx(`technology-${TypographyClasses.container}`)}
        >
          <div className={cx(`left-${TypographyClasses.container}`)}>
            {images.slice(0, 3)}
          </div>
          <div className={cx(`right-${TypographyClasses.container}`)}>
            {images.slice(3)}
          </div>
        </div>
        <div className={cx(`mobile-${TypographyClasses.container}`)}>
          {images}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
