import CoFounders from '@/public/images/jakub-founder.jpg';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import styles from './WhoWeAre.module.scss';

export const WhoWeAre = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  return (
    <section id="who-we-are" className={styles.whoWeAre}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <p className={styles.subtitle}>
            {t('component.whoweare.subtitle')}
          </p>
          <h2 className={styles.title}>
            {t('component.whoweare.title')}
          </h2>

          <div className={styles.content}>
            <div className={styles.leftColumn}>
              <div className={styles.imageWrapper}>
                <img
                  src={CoFounders.src}
                  alt="Nerdbord Co-founders"
                />
              </div>
            </div>

            <div className={styles.rightColumn}>
              {/* <p className={styles.description}>
                {t('component.whoweare.subheader')}
              </p> */}

              <div className={styles.foundersDetails}>
                <div className={styles.founder}>
                  <h3>Jakub Wąsowski</h3>
                  <p className={styles.role}>Twórca Nerdborda</p>
                  <p className={styles.bio}>
                    Jestem programistą-przedsiębiorcą z ponad 9-letnim
                    doświadczeniem w budowaniu produktów cyfrowych.
                    Doświadczenie zawodowe zdobywałem, pracując jako
                    freelancer, w korporacjach, startupach oraz
                    software house'ach. Nerdborda rozwijam już od
                    ponad 3 lat, przeszkoliłem na nim ponad 1000
                    programistów i programistek.
                    <br /> <br />
                    Na codzień wspieram firmy we wdrażaniu AI w ramach{' '}
                    <a
                      href="https://wasowski.tech"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.companyLink}
                    >
                      wasowski.tech
                    </a>{' '}
                    oraz rozwijam platformę open source do łączenia
                    specjalistów IT z osobami nietechnicznymi -{' '}
                    <a
                      href="https://devhunting.co/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.companyLink}
                    >
                      DevHunting
                    </a>
                    . Kiedy nie programuję, możesz znaleźć mnie na
                    macie brazylijskiego jiu-jitsu lub tworzącego
                    muzykę elektroniczną.
                  </p>

                  <a
                    href="https://www.linkedin.com/in/jwasowski"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.linkedinLink}
                  >
                    Złapmy się na LinkedIn :-)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
