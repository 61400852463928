import { IconModel } from '@/landing/models/IconModel';

export const FaqIconArrowDown = ({
  className,
  width,
  height,
}: IconModel) => {
  const iconWidth = width ? width : 12;
  const iconHeight = height ? height : 8;

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      fill="none"
      viewBox="0 0 12 8"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Frequently Asked Questions on Nerdbord</title>
      <desc>
        An icon representing frequently asked questions about Nerdbord
      </desc>
      <path
        d="M10.474 1.526L6 6 1.526 1.526"
        stroke="#A1A1AA"
        strokeWidth="1.7"
        strokeLinecap="round"
      ></path>
    </svg>
  );
};
