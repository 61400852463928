import { TypographyClasses } from '@/landing/styles/tokens';
import AiPoweredImg from '@/public/images/ai-code-review.jpg';
import CertificateImg from '@/public/images/certificate.jpg';
import GamificationImg from '@/public/images/landing/gamification.webp';
import GitflowImg from '@/public/images/landing/gitflow.webp';
import PairCodingImg from '@/public/images/landing/paircoding.webp';
import WeeklyMeetingImg from '@/public/images/landing/weekly.webp';
import ProjectSelection from '@/public/images/project-selection.jpg';
import classNames from 'classnames/bind';
import Image from 'next/image';
import styles from './ReinventedImages.module.scss';

const cx = classNames.bind(styles);

export const StartProjectImages = () => {
  return (
    <div className={cx(`img-${TypographyClasses.wrapper}`)}>
      <Image
        src={ProjectSelection}
        width={502}
        alt="Start Project"
        className={cx(`${TypographyClasses.img}-background2`)}
      />
    </div>
  );
};

export const CertificateImages = () => {
  return (
    <div className={cx(`img-${TypographyClasses.wrapper}`)}>
      <Image
        src={CertificateImg}
        width={502}
        height={293}
        className={cx(`${TypographyClasses.img}-background2`)}
        alt="Certificate"
      />
    </div>
  );
};

export const GitflowProcessImages = () => {
  return (
    <div className={cx(`img-${TypographyClasses.wrapper}`)}>
      <Image
        src={GitflowImg}
        className={cx(`${TypographyClasses.img}-background`)}
        alt="Nerdbord Integrated Gitflow for Project Management"
      />
    </div>
  );
};

export const AiPoweredImages = () => {
  return (
    <div className={cx(`img-${TypographyClasses.wrapper}`)}>
      <Image
        src={AiPoweredImg}
        className={cx(`${TypographyClasses.img}-background`)}
        alt="AI-Powered Code Reviews: Instant Feedback for Learning"
        loading="lazy"
      />
    </div>
  );
};

export const GamificationImages = () => {
  return (
    <div className={cx(`img-${TypographyClasses.wrapper}`)}>
      <Image
        src={GamificationImg}
        className={cx(`${TypographyClasses.img}-background2`)}
        alt="Stay Motivated with Gamified Learning on Nerdbord"
        loading="lazy"
      />
    </div>
  );
};

export const WeeklyMeetingImages = () => {
  return (
    <div className={cx(`img-${TypographyClasses.wrapper}`)}>
      <Image
        src={WeeklyMeetingImg}
        className={cx(`${TypographyClasses.img}-background`)}
        alt="Mentor Meetings: Support and Guidance on Nerdbord"
        loading="lazy"
      />
    </div>
  );
};

export const PairCodingImages = () => {
  return (
    <div className={cx(`img-${TypographyClasses.wrapper}`)}>
      <Image
        src={PairCodingImg}
        className={cx(`${TypographyClasses.img}-background2`)}
        alt="Master Teamwork with Pair Coding on Nerdbord"
        width={502}
        height={293}
        loading="lazy"
      />
    </div>
  );
};
