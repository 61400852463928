import { TypographyClasses } from '@/landing/styles/tokens';
import classNames from 'classnames/bind';
import Image, { StaticImageData } from 'next/image';
import { TestimonialsQuotesIcon } from '../../Icons/TestimonialsIcon/TestimonialsQuotesIcon';
import IconWrapper from '../../IconWrapper/IconWrapper';
import { Anchor } from '../../UI/Anchor';
import styles from './TestimonialCard.module.scss';

type TestimonialCardProps = {
  title: string;
  subtitle: string;
  content: string;
  href: string;
  src: StaticImageData;
  alt: string;
  color: 'black' | 'white';
};

const cx = classNames.bind(styles);

const TestimonialCard = ({
  title,
  subtitle,
  href,
  content,
  src,
  alt,
  color,
}: TestimonialCardProps) => {
  return (
    <div className={cx(TypographyClasses.wrapper)}>
      <div className={cx(TypographyClasses.content)}>
        <div>
          <IconWrapper
            icon={TestimonialsQuotesIcon}
            className={cx('quotes')}
          />
        </div>
        <div className={cx('testimonial-wrapper')}>
          <p className={cx(`${TypographyClasses.content}-${color}`)}>
            {content}
          </p>
          <div className={cx(TypographyClasses.container)}>
            <div className={cx(`img-${TypographyClasses.wrapper}`)}>
              <Image
                src={src}
                alt={alt}
                className={cx(TypographyClasses.img)}
                loading="lazy"
              />
            </div>
            <div className={cx('avatar')}>
              <p
                className={cx(`${TypographyClasses.title}-${color}`)}
              >
                {title}
              </p>
              <p
                className={cx(
                  `${TypographyClasses.subtitle}-${color}`
                )}
              >
                <Anchor href={href}>{subtitle}</Anchor>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
