import Avatar1 from '@/public/images/landing/avatar1.webp';
import Avatar2 from '@/public/images/landing/avatar2.webp';
import Avatar3 from '@/public/images/landing/avatar3.webp';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import TestimonialCard from './TestimonialCard';
import styles from './Testimonials.module.scss';

const testimonials = {
  section1: {
    src: Avatar1,
    alt: `Hanna Saczuk, aspiring programmer, shares her experience with Nerdbord's learning platform.`,
    content: `Each project gives me new opportunities for development - these aren't just hard skills but the soft ones too. In my case that’s mostly communication, in particular asking questions.  Since joining, I've made a huge progress, I even forget that once I had difficulty with this. Another thing worth mentioning is the process of preparation to the future IT job:  I appreciate retro meetings & team projects to get used to this working style. Right now I'm an individual worker so working in a teams was something new for me.`,
    title: 'Hanna Saczuk',
    subtitle: '@hansac91',
    href: 'https://github.com/hansac91',
  },
  section2: {
    src: Avatar2,
    alt: 'Norbert Wawrzonek, a satisfied Nerdbord student, discusses his growth as a developer.',
    content: `I am fascinated by the entire project. The platform integrated with Github and awesome code reviews. I rate the projects, ideas, and their design a 10. The team spirit within the group is amazing. Everything is a plus. I really like how projects are broken down into individual tasks. The graphics are great, and the idea as well. And most importantly, I am learning new things as I write them. Everything comes together in an awesome way.`,
    title: 'Norbert Wawrzonek',
    subtitle: '@wawrzonnn',
    href: 'https://github.com/wawrzonnn',
  },
  section3: {
    src: Avatar3,
    alt: `Michał Makuch', a successful graduate of Nerdbord's coding courses, talks about his career advancement.`,
    content: `I really love project-oriented approach. Until now I was relaying on curses and tutorials, but theory is nothing in comparison to practice. Now i see how many gaps in knowledge and skills I have to fulfill. It slows me down, because I have to return to theory and re-learn many things, but I know it's the best and fastest way to become fluent :) `,
    title: 'Michał Makuch',
    subtitle: '@mimakuch',
    href: 'https://github.com/mimakuch',
  },
};

const cx = classNames.bind(styles);

export const Testimonial1 = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const data = useMemo(() => {
    return {
      src: Avatar1,
      alt: `Hanna Saczuk, aspiring programmer, shares her experience with Nerdbord's learning platform.`,
      content: t('testimonial.hania'),
      title: 'Hanna Saczuk',
      subtitle: '@hansac91',
      href: 'https://github.com/hansac91',
    };
  }, [t]);
  return (
    <section className={cx('testimonials-black')}>
      <TestimonialCard
        title={data.title}
        subtitle={data.subtitle}
        content={data.content}
        src={data.src}
        alt={data.alt}
        href={data.href}
        color="black"
      />
    </section>
  );
};

export const Testimonial2 = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const data = useMemo(() => {
    return {
      src: Avatar2,
      alt: 'Norbert Wawrzonek, a satisfied Nerdbord student, discusses his growth as a developer.',
      content: t('testimonial.norbert'),
      title: 'Norbert Wawrzonek',
      subtitle: '@wawrzonnn',
      href: 'https://github.com/wawrzonnn',
    };
  }, [t]);
  return (
    <section className={cx('testimonials-white')}>
      <TestimonialCard
        title={data.title}
        subtitle={data.subtitle}
        content={data.content}
        href={data.href}
        src={data.src}
        alt={data.alt}
        color="white"
      />
    </section>
  );
};

export const Testimonial3 = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const data = useMemo(() => {
    return {
      src: Avatar3,
      alt: `Michał Makuch', a successful graduate of Nerdbord's coding courses, talks about his career advancement.`,
      content: t('testimonial.michal'),
      title: 'Michał Makuch',
      subtitle: '@mimakuch',
      href: 'https://github.com/mimakuch',
    };
  }, [t]);
  return (
    <section className={cx('testimonials-black')}>
      <TestimonialCard
        title={data.title}
        subtitle={data.subtitle}
        content={data.content}
        href={data.href}
        src={data.src}
        alt={data.alt}
        color="black"
      />
    </section>
  );
};
