import { TypographyClasses } from '@/landing/styles/tokens';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Faq.module.scss';
import FaqItem from './FaqItem';

const cx = classNames.bind(styles);

const Faq = () => {
  const tabRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const [isVisible, setIsVisible] = useState(false);

  const faqs = useMemo(
    () => [
      {
        header: t('faq.header1'),
        text: t('faq.text1'),
      },
      {
        header: t('faq.header2'),
        text: t('faq.text2'),
      },
      {
        header: t('faq.header4'),
        text: t('faq.text4'),
      },
      {
        header: t('faq.header3'),
        text: t('faq.text3'),
      },
      // {
      //   header: t('faq.header5'),
      //   text: t('faq.text5'),
      // },
      // {
      //   header: t('faq.header6'),
      //   text: t('faq.text6'),
      // },
    ],
    [t]
  );

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    }, observerOptions);

    if (tabRef.current) {
      observer.observe(tabRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section id="faq" className={cx('faq')} ref={tabRef}>
      <div className={cx(TypographyClasses.wrapper)}>
        <div>
          <div
            className={`${cx(`${TypographyClasses.container}`)} ${
              isVisible ? cx('sticky') : ''
            }`}
          >
            <p className={cx(TypographyClasses.subheader)}>
              {t('faq.subheader')}
            </p>
            <h2 className={cx(TypographyClasses.title)}>
              {t('faq.title')}
            </h2>
            <p className={cx(TypographyClasses.subtitle)}>
              {t('faq.subtitle')}
            </p>
          </div>
        </div>
        <div className={cx(TypographyClasses.content)}>
          {faqs.map((faq, index) => {
            return (
              <FaqItem
                key={index}
                header={faq.header}
                text={faq.text}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
