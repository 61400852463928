import { TypographyClasses } from '@/landing/styles/tokens';
import classNames from 'classnames/bind';
import styles from './IndustryBox.module.scss';

type IndustryBoxProps = {
  emoji: string;
  title: string;
  content: string;
};

const cx = classNames.bind(styles);

const IndustryBox = ({ emoji, title, content }: IndustryBoxProps) => {
  return (
    <div className={cx('box')}>
      <div className={cx('wrapper')}>
        <p className={cx(TypographyClasses.emoji)}>{emoji}</p>
        <h3 className={cx(TypographyClasses.title)}>{title}</h3>
        <p className={cx(TypographyClasses.content)}>{content}</p>
      </div>
    </div>
  );
};

export default IndustryBox;
