import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import { Button } from '../../UI/Button';
import styles from './Join.module.scss';

const Join = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const handleJoinClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const programsSection = document.querySelector('#our-programs');
    if (programsSection) {
      programsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className={styles.join}>
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>
          {t('join.thoughtfully-built')}
        </p>
        <h2 className={styles.title}>{t('join.try-out-projects')}</h2>
        <p className={styles.subheader}>{t('join.description')}</p>
        <Button
          onClick={handleJoinClick}
          variant="primary"
          aria-label="Join Nerdbord for free"
        >
          {t('join.join-for-free')}
        </Button>
      </div>
    </section>
  );
};

export default Join;
