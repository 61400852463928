import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@/components/Button/Button';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import format from 'date-fns/format';
import styles from './SingleProgramGroup.module.scss';

interface SingleProgramGroupProps {
  title: string;
  price: number;
  currency: string;
  showPrice?: boolean;
  priceBeforeDiscount?: number;
  isSubscription?: boolean;
  groupPageUrl: string;
  imageSrc: string | null;
  shortDescription: string;
  endDate?: string | null;
  startAt?: string | null;
}

export const SingleProgramGroup = ({
  showPrice = true,
  ...props
}: SingleProgramGroupProps) => {
  const router = useRouter();
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const endDate = props.endDate
    ? new Date(Number(props.endDate))
    : new Date();
  const startDate = props.startAt
    ? new Date(Number(props.startAt))
    : new Date();

  const formattedEndDate = format(endDate, 'dd.MM.yyyy');
  const formattedStartDate = format(startDate, 'dd.MM.yyyy');

  const { isMobile } = useMediaQuery();

  return (
    <div
      onClick={() => router.push(props.groupPageUrl)}
      className={styles.singleProgram}
    >
      <div className={styles.imageWrapper}>
        {props.imageSrc && (
          <Image
            width={0}
            height={0}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
            alt={props.shortDescription}
            src={props.imageSrc}
          />
        )}
      </div>
      <div className={styles.programData}>
        <h2 className={styles.title}>{props.title}</h2>
        <p className={styles.shortDescription}>
          {props.shortDescription}
        </p>
        <nav className={styles.nav}>
          <Button
            variant={ButtonVariant.orange}
            fullWidth={isMobile}
            size={!isMobile ? ButtonSize.small : ButtonSize.large}
            onClick={() => router.push(props.groupPageUrl)}
          >
            {t('singleprogramgroup.checkitout')}
          </Button>
          <div>
            {showPrice && (
              <span className={styles.chip}>
                {props.price !== 0
                  ? props.price
                  : t('singleprogramgroup.free')}{' '}
                {props.price !== 0 && props.currency}{' '}
                {props.isSubscription && (
                  <span>/ {t('singleprogramgroup.month')}</span>
                )}
                {props.priceBeforeDiscount && (
                  <span className={styles.discount}>
                    {props.priceBeforeDiscount} {props.currency}
                  </span>
                )}
              </span>
            )}
            {props.endDate && (
              <span className={styles.highlightedPill}>
                {t('singleprogramgroup.availableto')}{' '}
                {formattedEndDate}
              </span>
            )}
            {props.startAt && (
              <span className={styles.highlightedPill}>
                Start {formattedStartDate}
              </span>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};
